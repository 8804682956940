import React, { useEffect } from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import '../style/mdb.scoped.scss'
import { Link } from "gatsby"

const MDB = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''

  let windowH = 0 // 窗口高度
  let windowW = 0
  const boxNodes = []
  
  useEffect(() => {
    windowH = document.body.clientHeight
    windowW = document.body.clientWidth
    const nodes = document.querySelectorAll('.video-box')
    nodes.forEach(node => {
      const videoNode = node.querySelector('video')
      const topH = node.offsetTop
      const height = node.clientHeight
      boxNodes.push({
        topH,
        height,
        videoNode
      })
    })

    scrollListenerFunc()
    window.addEventListener("scroll", scrollListenerFunc)

    return () => {
      window.removeEventListener("scroll", scrollListenerFunc)
    }
  }, [])

  // 监听函数
  function scrollListenerFunc () {
    if (windowW <= 768) return
    // 内容完全在可视区内部时播放。即 scrollTopVal < box.offsetTop && (scrollTopVal + windowH) > (box.offsetTop + box.clientHeight)
    // 由于视频上下黑色间距较多，这里各自给了100的区间
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop
    boxNodes.forEach(({topH, height, videoNode}) => {
      if (scrollTopVal < topH + 100 && (scrollTopVal + windowH) > (topH + height - 100)) {
        if (videoNode.paused) videoNode.play()
      } else {
        videoNode.pause()
      }
    })
  }
  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
    <Seo title="MogDB: MDB"/>
      <div className="page-container">
        <div className="mdb-banner">
          <div className="product-banner-box">
            <i className="bg"></i>
            <h1 className="product-banner-title"><FormattedMessage id="mdb.pageTitle" /><i className="mdb"></i></h1>
            <div className={`product-banner-desc ${pageLang === 'en' ? 'product-banner-desc-en' : ''}`}>
              <p><FormattedMessage id="mdb.pageContent" /></p>
              <p><FormattedMessage id="mdb.pageContent1" /></p>
            </div>
            <div className="btn-group">
              <Link to={`${pagePrefix}/downloads/mdb/all/`} className="btn download"><FormattedMessage id="index.download" /></Link>
              <a href={`https://docs.mogdb.io/${pageLang}/mdb/`} rel="noreferrer" target="_blank" className="btn searchDoc"><FormattedMessage id="index.docs" /></a>
            </div>
            <div className="video-box" id='video-1'>
              <video preload="auto" loop muted playsInline className="video">
                <source src="https://cdn-mogdb.enmotech.com/website/mdb-video/banner.webm" type="video/webm" />
              </video>
            </div>
          </div>
        </div>
        <div className="product-content">
          <div className="product-video video-migrate">
            <div className="video-box" id='video-2'>
              <video preload="auto" loop muted playsInline className="video">
                <source src="https://cdn-mogdb.enmotech.com/website/mdb-video/p1.webm" type="video/webm" />
              </video>
            </div>
            <div className="text-box">
              <div className="head"><FormattedMessage id="mdb.migrate" /></div>
              <div className={`desc ${pageLang === 'en' ? 'desc-en' : ''}`}><FormattedMessage id="mdb.migrateDesc" /></div>
              <div className="title"><FormattedMessage id="mdb.useCase" /></div>
              <div className="scen-desc"><FormattedMessage id="mdb.migrateCase" /></div>
            </div>
          </div>
          <div className="product-video video-increment">
            <div className="text-box">
              <div className="head"><FormattedMessage id="mdb.increment" /></div>
              <div className={`desc ${pageLang === 'en' ? 'desc-en' : ''}`}> <FormattedMessage id="mdb.incrementDesc" /></div>
              <div className="title"><FormattedMessage id="mdb.useCase" /></div>
              <div className="scen-desc"><FormattedMessage id="mdb.incrementCase" /></div>
            </div>
            <div className="video-box" id='video-3'>
              <video preload="auto" loop muted playsInline className="video">
                <source src="https://cdn-mogdb.enmotech.com/website/mdb-video/p2.webm" type="video/webm" />
              </video>
            </div>
          </div>
          <div className="product-video video-compatible">
            <div className="video-box" id='video-4'>
              <video preload="auto" loop muted playsInline className="video">
                <source src="https://cdn-mogdb.enmotech.com/website/mdb-video/p3.webm" type="video/webm" />
              </video>
            </div>
            <div className="text-box">
              <div className="head"><FormattedMessage id="mdb.compatible" /></div>
              <div className={`desc ${pageLang === 'en' ? 'desc-en' : ''}`}><FormattedMessage id="mdb.compatibleDesc" /></div>
              <div className="title"><FormattedMessage id="mdb.useCase" /></div>
              <div className="scen-desc"><FormattedMessage id="mdb.compatibleCase" /></div>
            </div>
          </div>
          <div className="product-video video-verify">
            <div className="text-box">
              <div className="head"><FormattedMessage id="mdb.verify" /></div>
              <div className={`desc ${pageLang === 'en' ? 'desc-en' : ''}`}><FormattedMessage id="mdb.verifyDesc" /></div>
              <div className="title"><FormattedMessage id="mdb.useCase" /></div>
              <div className="scen-desc"><FormattedMessage id="mdb.verifyCase" /></div>
            </div>
            <div className="video-box" id='video-5'>
              <video preload="auto" loop muted playsInline className="video">
                <source src="https://cdn-mogdb.enmotech.com/website/mdb-video/p4.webm" type="video/webm" />
              </video>
            </div>
          </div>
        </div>
        <div className="mdb-experience">
          <div className="title"><FormattedMessage id="index.experience" /></div>
          <div className="content"><FormattedMessage id="mdb.experienceContent" /></div>
          <div className="btn-group">
            <a href={`https://docs.mogdb.io/${pageLang}/mdb/latest/release`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a>
            <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default MDB